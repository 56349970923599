import React from 'react'
import { Heading, Text } from 'rebass'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'

export default function ThankYou() {
  return (
    <Layout>
      <SEO title="Thank you" />

      <Container my={4}>
        <Heading as="h1" mb={3}>
          Thank you
        </Heading>
        <Text>Thanks for your enquiry, we will endeavour to get back to you shortly.</Text>
      </Container>
    </Layout>
  )
}
